import { isEqual } from 'date-fns';
import React, {
  createContext,
  ReactText,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';
import { Schedule } from 'types';
import { GameView, ScheduleDay } from 'types/dto';

interface TimelineContextValue {
  isLoading: boolean;
  dayModified: boolean;
  schedule?: Schedule;
  editingSchedule?: Schedule;
  unscheduledGames: GameView[];
  selectedDay?: ScheduleDayWithGameLookup;
  selectedGame?: GameView;
  editSelectedDay: (day: ScheduleDay) => void;
  setDayModified: (modified: boolean) => void;
  setUnscheduledGames: (games: GameView[]) => void;
  setSelectedGame: (game?: GameView) => void;
  setSelectedDate: (day: Date) => void;
  setIsLoading: (isLoading: boolean) => void;
  onGameSelect?: (game: GameView) => void;
}

const defaultValue: TimelineContextValue = {
  unscheduledGames: [],
  setDayModified: () => {},
  setUnscheduledGames: () => {},
  editSelectedDay: () => {},
  setSelectedGame: () => {},
  setSelectedDate: () => {},
  setIsLoading: () => {},
  onGameSelect: () => {},
  isLoading: false,
  dayModified: false,
};

type ScheduleDayWithGameLookup = Omit<ScheduleDay, 'games'> & {
  games: { [id: number]: GameView };
};
const setScheduleDayWithGameLookup = (
  newDay: ScheduleDay | undefined,
  setDay: React.Dispatch<
    React.SetStateAction<ScheduleDayWithGameLookup | undefined>
  >
) => {
  if (newDay) {
    const games: { [id: number]: GameView } = {};
    newDay.games.forEach(
      (g) =>
        (games[g.id] = {
          ...g,
          scheduledAt: new Date(g.scheduledAt),
          startTime: new Date(g.startTime),
          endTime: new Date(g.endTime),
        })
    );
    setDay({ ...newDay, games });
  }
};

const TimelineContext = createContext<TimelineContextValue>(defaultValue);

export const useTimelineContext = () => useContext(TimelineContext);

export const TimelineContextProvider: React.FC<{
  schedule?: Schedule;
  editingSchedule?: Schedule;
  onEditingScheduleChange?: (newSchedule: Schedule) => void;
  onGameSelect?: (game: GameView) => void;
  onScheduleValidChange: (valid: boolean) => void;
}> = ({
  children,
  editingSchedule,
  schedule,
  onEditingScheduleChange,
  onGameSelect,
  onScheduleValidChange,
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [dayModified, setDayModified] = useState(false);
  const [selectedGame, setSelectedGame] = useState<GameView | undefined>();
  const [selectedDate, setSelectedDate] = useState<Date | undefined>();
  const [selectedDay, setSelectedDay] = useState<
    ScheduleDayWithGameLookup | undefined
  >();
  const [unscheduledGames, setUnscheduledGames] = useState<GameView[]>([]);

  useEffect(() => {
    if (selectedDay && editingSchedule && onEditingScheduleChange) {
      const newDays = [...editingSchedule.days];
      const dayIndex = newDays.findIndex((day) =>
        isEqual(new Date(day.date), new Date(selectedDay.date))
      );
      if (dayIndex > -1) {
        newDays.splice(dayIndex, 1, {
          ...selectedDay,
          games: Object.values(selectedDay.games),
        });
        onEditingScheduleChange({ ...editingSchedule, days: newDays });
      }
    }
  }, [selectedDay]);

  useEffect(() => {
    onScheduleValidChange(unscheduledGames.length === 0);
  }, [unscheduledGames]);

  useEffect(() => {
    if (
      selectedDate &&
      (!selectedDay ||
        !isEqual(new Date(selectedDay?.date), new Date(selectedDate)))
    ) {
      const day = editingSchedule?.days.find((day) =>
        isEqual(new Date(day.date), new Date(selectedDate))
      );
      if (day) {
        setScheduleDayWithGameLookup(day, setSelectedDay);
      }
    }
  }, [editingSchedule?.days, selectedDay, selectedDate]);

  useEffect(() => {
    if (!selectedDate && editingSchedule && editingSchedule.days.length) {
      setSelectedDate(editingSchedule?.days[0].date);
    }
  }, [editingSchedule]);

  return (
    <TimelineContext.Provider
      value={{
        ...defaultValue,
        selectedGame,
        selectedDay,
        schedule,
        isLoading,
        dayModified,
        unscheduledGames,
        setDayModified,
        setUnscheduledGames,
        setSelectedGame,
        setIsLoading,
        setSelectedDate,
        editSelectedDay: (newDay) =>
          setScheduleDayWithGameLookup(newDay, setSelectedDay),
        onGameSelect,
      }}
    >
      {children}
    </TimelineContext.Provider>
  );
};
