export type SportEvent = {
  id: number;
  name: string;
  description: string;
  startDate: Date;
  endDate: Date;
};

export type SportEventView = {
  config: SportEventConfig;
  id: number;
  name: string;
  description: string;
  startDate: Date;
  endDate: Date;
  numGames: number;
  numTeams: number;
  numPlayers: number;
};

export type SportEventConfig = {
  flags: {
    isLive: boolean;
    showPlayoffs: boolean;
    allowLogin: boolean;
    allowRegistration: boolean;
    showStandings: boolean;
    allowPlayoffDelete: boolean;
  };
};

export type User = {
  id: number;
  firstName: string;
  middleName: string | null;
  lastName: string;
  bkmsId: string | null;
  email: string;
  phoneNumber: string;
  shirtSize: string | null;
  sweatpantSize: string | null;
  dob: Date;
  center: string;
  createdAt: Date;
  updatedAt: Date;
  role: Role;
};

const Role = {
  SUPER_ADMIN: 'SUPER_ADMIN',
  LEAGUE_ADMIN: 'LEAGUE_ADMIN',
  EVENT_ADMIN: 'EVENT_ADMIN',
  REFEREE: 'REFEREE',
  SCORE_KEEPER: 'SCORE_KEEPER',
  USER: 'USER',
};

export type Role = typeof Role[keyof typeof Role];

export type Team = {
  id: number;
  name: string;
  leagueId: number;
  leagueName: string;
  eventId: number;
  eventName: string;
  captainId: number;
  drawId: number;
  shirtsGiven: boolean | null;
  rank: number | null;
  gamesWon: number;
  gamesLost: number;
  gamesTied: number;
};

export interface DetailedTeam extends Team {
  players: Player[];
}

export type Court = {
  id: number;
  name: string;
  eventId: number;
};

export type Game = {
  id: number;
  homeTeamId: number;
  awayTeamId: number;
  scheduledAt: Date;
  startTime: Date;
  endTime: Date;
  courtId: number;
  leagueId: number;
  played: boolean;
  homeTeamScore: number;
  awayTeamScore: number;
  eventId: number;
  nextGameId?: number;
  nextGameIdForLoser?: number;
  mvpId?: number;
};

export type BulkCreateGamesRequest = {
  leagueId: number;
  startTime: Date;
  gameDuration: number;
  timeBetweenGames: number;
  courts: Court[];
  requestType: 'ranking' | 'random';
}

export type GameView = {
  scoreKeeperName: string;
  id: number;
  eventId: number;
  courtId: number;
  leagueId: number;
  scoreKeeperId: number;
  awayTeamName: string;
  awayTeamId: number;
  awayTeamDrawId: number;
  awayTeamScore: number;
  awayTeamPD: number;
  homeTeamName: string;
  homeTeamId: number;
  homeTeamDrawId: number;
  homeTeamScore: number;
  homeTeamPD: number;
  played: boolean;
  playoff: boolean;
  verified: boolean;
  scheduledAt: Date;
  startTime: Date;
  endTime: Date;
  courtName: string;
  mvpId?: number;
};

export type League = {
  id: number;
  name: string;
  description: string;
};

export type LeagueEvent = {
  id: number;
  name: string;
  description: string;
  numGames: number;
};

export interface Player extends Attendee {
  playerId: number;
  totalPoints?: number;
  totalRebounds?: number;
  totalSteals?: number;
  totalAssists?: number;
  totalBlocks?: number;
  teamId?: number;
  teamPlayersId?: number;
  drawId?: number;
}

export interface Attendee {
  id: number;
  attendeeId: number;
  bkmsId?: number;
  userId: number;
  eventId: number;
  name: string;
  email: string;
  phone: string;
  dob: string;
  present?: boolean;
  hotelId?: number;
  hotelName?: string;
  roomNumber?: number;
  keyGiven?: boolean;
  smrutiBhetGiven?: boolean;
  shirtSize?: string;
  centerName: string;
  leagueId: number;
}

export type PlayerStats = {
  id: number;
  playerId: number;
  gameId: number;
  teamId: number;
  leagueId: number;
  name: string;
  onePoints: number;
  twoPoints: number;
  threePoints: number;
  rebounds: number;
  assists: number;
  steals: number;
  blocks: number;
  turnovers: number;
  fouls: number;
  foulsDrawn: number;
};

export type GameStats = {
  game: GameView;
  homeTeam: PlayerStats[];
  awayTeam: PlayerStats[];
  scoreKeeperName: string;
};

export type Schedule = {
  courts: Court[];
  days: ScheduleDay[];
};

export type ScheduleDay = {
  date: Date;
  startTime: Date;
  endTime: Date;
  games: GameView[];
};

export type CreateSportEvent = {
  event: Omit<SportEvent, 'id'>;
  leagues?: number[];
};

export type LoginResponse = {
  accessToken: string;
  user: User & { organizationUser?: OrganizationUser };
};

export type Round = {
  id: number;
  title: string;
  games: (Pick<Game, 'id'> & Partial<Game>)[];
};

export type PlayoffSchedule = {
  rounds: Round[];
};
export type UpdatePlayerStats = Partial<{
  onePoints: number;
  twoPoints: number;
  threePoints: number;
  rebounds: number;
  steals: number;
  assists: number;
  blocks: number;
  turnovers: number;
  fouls: number;
  foulsDrawn: number;
}>;

export type GetFeedResponse = {
  latestCursor: number | undefined;
  posts: PostView[];
  lastCursor: number;
};

export type CreatePostResponse = {
  url: string;
  contentType: string;
  post: PostView;
};

export type PostView = {
  id: number;
  title: string;
  description: string;
  eventId: number;
  userId: number;
  mediaId: string;
  mediaType: string;
  approved: boolean | null;
  approvedBy: number | null;
  url: string;
  timeUploaded: Date;
  timeStamp: Date;
  numLikes: number;
  numComments: number;
};

export type OrgEventRole = {
  id: number;
  eventId: number;
  organizationUserId: number;
  role: EventRoleType;
};

export type OrganizationConfig = {
  flags: {};
};

export type Organization = {
  id: number;
  name: string;
  description: string;
  location: string;
  ownerId: number;
  archived: boolean;
  config: OrganizationConfig;
};

export type OrganizationUser = {
  id: number;
  organizationId: number;
  userId: number;
  organizationRole: string;
  eventRoles: OrgEventRole[];
};

export type OrganizationsByUser = OrganizationUser & {
  organization: Organization;
};

export type Center = {
  id: number;
  name: string;
  description: string;
};

export type Page<T> = {
  items: T[];
  total: number;
  page: number;
  pageSize: number;
};

const OrganizationRoleType = {
  ORG_OWNER: 'ORG_OWNER',
  ORG_ADMIN: 'ORG_ADMIN',
  READ_WRITE: 'READ_WRITE',
  READ_ONLY: 'READ_ONLY',
};

export const EventRoleType = {
  EVENT_OWNER: 'EVENT_OWNER',
  EVENT_ADMIN: 'EVENT_ADMIN',
  EVENT_STAFF: 'EVENT_STAFF',
  PLAYER: 'PLAYER',
  SCORE_KEEPER: 'SCORE_KEEPER',
};

export type OrganizationRoleType =
  typeof OrganizationRoleType[keyof typeof OrganizationRoleType];

export type EventRoleType = typeof EventRoleType[keyof typeof EventRoleType];

export interface OrgMember {
  id: number;
  firstName: string;
  lastName: string;
  email: string;
  phoneNumber: string;
  organizationRole: OrganizationRoleType;
}

export interface SportEventMember {
  userId: number;
  organizationUserId: number;
  firstName: string;
  lastName: string;
  email: string;
  phoneNumber: string;
  eventRole: EventRoleType;
}

export type SubEvent = {
  id: number;
  eventId: number;
  title: string;
  description: string;
  time: Date;
};

export type CreateSubEvent = {
  subEvent: Omit<SubEvent, 'id' | 'eventId'>;
};

export type Menu = {
  id: number;
  eventId: number;
  title: string;
  description: string;
  startTime: Date;
  endTime: Date;
  items: FoodItem[];
};

export type CreateMenuEvent = {
  menu: Omit<Menu, 'id'>;
};

export type FoodItem = {
  menuId: number;
  title: string;
  description: string;
};

export type Hotel = {
  id: number;
  eventId: number;
  name: string;
  address?: string;
};

export type PushNotificationSubscriptionType = 'HOTEL' | 'GAME' | 'GENERAL' | 'PLAYER' | 'ATTENDEE';

export type PushNotificationSubscription = {
  id: number;
  notificationIds: number[];
  subscriptionType: PushNotificationSubscriptionType;
  resourceId?: number;
  eventId: number;
}

export type PushNotificationMessage = {
  id: number;
  notificationSubscriptionId: number;
  title: string;
  body: string;
}

export type PushQueueNotificationRequest = {
  title: string;
  body: string;
  subscriptionType: PushNotificationSubscriptionType;
  eventId: number;
  resourceId?: number;
}

export type RefreshNotificationForEventResponse = {
  success: boolean;
  message: string;
  details: {
    total: number;
    updated: number;
    skipped: number;
    errors: string[];
  };
}
