import {
  Button,
  FormControl,
  FormLabel,
  HStack,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  NumberDecrementStepper,
  NumberIncrementStepper,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  Select,
  Switch,
} from '@chakra-ui/react';
import {useEffect, useState} from 'react';
import {LeagueEvent, Team} from '../../types';

interface GenerateBracketModalProps {
  isOpen: boolean;
  onClose: () => void;
  eventId: number;
  league: LeagueEvent;
  teams: Team[];
  onSubmit: (payload: {
    numSeeds: number;
    persist: boolean;
    date: Date;
    doubleElimination: boolean;
    startLosersAfterRound?: number;
  }) => void;
}

export const GenerateBracketModal: React.FC<GenerateBracketModalProps> = ({
                                                                            isOpen,
                                                                            onClose,
                                                                            eventId,
                                                                            league,
                                                                            teams,
                                                                            onSubmit,
                                                                          }) => {
  const [numSeeds, setNumSeeds] = useState(teams.length / 2);
  const [date, setDate] = useState<Date>(new Date());
  const [persist, setPersist] = useState(true);
  const [doubleElimination, setDoubleElimination] = useState(false);
  const [startLosersAfterRound, setStartLosersAfterRound] = useState<number>(0);
  const [winnersRoundsOptions, setWinnersRoundsOptions] = useState<number[]>([]);

  useEffect(() => {
    const calculateWinnersRounds = () => {
      const rounds = Math.ceil(Math.log2(numSeeds))-2;
      const options = Array.from({length: rounds}, (_, i) => i);
      setWinnersRoundsOptions(options);
    };

    if (doubleElimination) {
      calculateWinnersRounds();
    }
  }, [numSeeds, doubleElimination]);

  // Helper function to format date to datetime-local input format
  const formatDateForInput = (date: Date): string => {
    const offset = date.getTimezoneOffset();
    const localDate = new Date(date.getTime() - offset * 60000);
    return localDate.toISOString().slice(0, 16);
  };

  // Helper function to handle datetime-local input change
  const handleDateTimeChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    // Check input validity before updating state
    if (e.target.validity.valid) {
      const newDate = new Date(e.target.value);
      setDate(newDate);
    }
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay/>
      <ModalContent>
        <ModalHeader>
          Generate Bracket
          <FormControl display="flex" alignItems="center">
            <FormLabel mb="0">Persist?</FormLabel>
            <Switch
              isChecked={persist}
              onChange={(e) => setPersist(e.target.checked)}
              id="persist"
            />
          </FormControl>
          <FormControl display="flex" alignItems="center">
            <FormLabel mb="0">Double Elimination?</FormLabel>
            <Switch
              isChecked={doubleElimination}
              onChange={(e) => {
                setDoubleElimination(e.target.checked);
                if (!e.target.checked) setStartLosersAfterRound(0);
              }}
              id="doubleElimination"
            />
          </FormControl>
        </ModalHeader>
        <ModalCloseButton/>
        <ModalBody>
          <FormControl key={league.id}>
            <FormLabel>Num. Seeds {league.name}:</FormLabel>
            <NumberInput
              value={numSeeds}
              onChange={(val) => setNumSeeds(parseInt(val))}
              size="sm"
            >
              <NumberInputField/>
              <NumberInputStepper>
                <NumberIncrementStepper/>
                <NumberDecrementStepper/>
              </NumberInputStepper>
            </NumberInput>
          </FormControl>
          <FormControl>
            <FormLabel>Date and Time</FormLabel>
            <input 
              type="datetime-local" 
              value={formatDateForInput(date)}
              onChange={handleDateTimeChange}
              className="w-full rounded-md border border-gray-300 p-2"
            />
          </FormControl>

          {doubleElimination && (
            <FormControl>
              <FormLabel>Start Losers Bracket After Winners Round</FormLabel>
              <Select
                value={startLosersAfterRound}
                onChange={(e) => setStartLosersAfterRound(parseInt(e.target.value))}
              >
                {winnersRoundsOptions.map((round) => (
                  <option key={round} value={round}>
                    Round {round+1}
                  </option>
                ))}
              </Select>
            </FormControl>
          )}
        </ModalBody>
        <ModalFooter>
          <HStack>
            <Button variant="ghost" onClick={onClose}>
              Close
            </Button>
            <Button
              onClick={() =>
                onSubmit({
                  numSeeds,
                  persist,
                  date,
                  doubleElimination,
                  startLosersAfterRound,
                })
              }
            >
              Generate
            </Button>
          </HStack>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};
