import {
  Box,
  Heading,
  VStack,
  Tabs,
  TabList,
  TabPanels,
  Tab,
  TabPanel,
  Text,
  Flex,
  Badge,
  Divider,
  HStack,
  useColorModeValue,
  Container,
  Spinner,
  Icon,
  useMediaQuery,
  Select,
  Input,
  InputGroup,
  InputLeftElement,
} from "@chakra-ui/react";
import { useMemo, useState, ReactElement } from "react";
import { useParams } from "react-router-dom";
import { useGetScheduleForEventQuery, useGetEventByIdQuery } from "../../../app/api";
import { MdGames, MdAccessTime, MdLocationOn, MdSearch, MdFilterList } from "react-icons/md";
import { format } from "date-fns";
import { GameView, ScheduleDay, Court, SportEventView } from "../../../types/dto";

interface GroupedGames {
  [key: string]: GameView[];
}

interface CourtOption {
  id: number;
  name: string;
}

// Separate loading component
const LoadingView = (): ReactElement => (
  <Box py={10} textAlign="center">
    <Spinner size="xl" />
    <Text mt={4}>Loading schedule...</Text>
  </Box>
);

// Separate error component
const ErrorView = (): ReactElement => (
  <Box py={10} textAlign="center">
    <Heading size="md">Schedule not available</Heading>
    <Text mt={4}>The schedule for this event is not available at this time.</Text>
  </Box>
);

// Main schedule display component
const ScheduleDisplay = ({ 
  event, 
  schedule, 
  isMobile 
}: { 
  event: SportEventView; 
  schedule: { courts: Court[]; days: ScheduleDay[] }; 
  isMobile: boolean;
}): ReactElement => {
  const [selectedCourt, setSelectedCourt] = useState<string>('all');
  const [searchQuery, setSearchQuery] = useState<string>('');

  // Get unique courts for the filter dropdown
  const courts: CourtOption[] = useMemo(() => {
    if (!schedule?.courts) return [];
    return schedule.courts.map((court: Court) => ({ 
      id: court.id, 
      name: court.name 
    }));
  }, [schedule]);

  // Group games by date and apply filters
  const gamesByDate: GroupedGames = useMemo(() => {
    if (!schedule?.days) return {} as GroupedGames;
    
    const groupedGames: GroupedGames = {};
    
    schedule.days.forEach((day: ScheduleDay) => {
      const dateStr = format(new Date(day.date), "yyyy-MM-dd");
      
      // Create a shallow copy of the games array
      let filteredGames = [...day.games];
      
      // Apply court filter if not 'all'
      if (selectedCourt !== 'all') {
        const courtId = parseInt(selectedCourt);
        filteredGames = filteredGames.filter(game => game.courtId === courtId);
      }
      
      // Apply search filter if search query exists
      if (searchQuery.trim() !== '') {
        const query = searchQuery.toLowerCase();
        filteredGames = filteredGames.filter(game => 
          game.homeTeamName.toLowerCase().includes(query) || 
          game.awayTeamName.toLowerCase().includes(query) || 
          game.courtName.toLowerCase().includes(query)
        );
      }
      
      // Sort by time
      filteredGames.sort(
        (a, b) => new Date(a.startTime).getTime() - new Date(b.startTime).getTime()
      );
      
      // Only add the date if there are games after filtering
      if (filteredGames.length > 0) {
        groupedGames[dateStr] = filteredGames;
      }
    });
    
    return groupedGames;
  }, [schedule, selectedCourt, searchQuery]);

  const dateLabels: string[] = useMemo(() => {
    return Object.keys(gamesByDate).sort();
  }, [gamesByDate]);

  const cardBg = useColorModeValue("white", "gray.800");
  const borderColor = useColorModeValue("gray.200", "gray.700");
  const accentColor = useColorModeValue("blue.500", "blue.300");
  const inputBg = useColorModeValue("white", "gray.700");

  return (
    <Box px={isMobile ? 2 : 5} py={4} maxW="100%" overflow="hidden">
      <Box mb={6} textAlign="center">
        <Heading size="xl" mb={2}>{event.name}</Heading>
        <Text fontSize="lg" color="gray.600">
          {format(new Date(event.startDate), "MMMM d")} - {format(new Date(event.endDate), "MMMM d, yyyy")}
        </Text>
      </Box>

      {/* Search and Filter Controls */}
      <Flex 
        direction={isMobile ? "column" : "row"} 
        gap={4} 
        mb={6}
        justifyContent="space-between"
        alignItems={isMobile ? "stretch" : "center"}
      >
        <InputGroup maxW={isMobile ? "100%" : "300px"}>
          <InputLeftElement pointerEvents="none">
            <Icon as={MdSearch} color="gray.400" />
          </InputLeftElement>
          <Input
            placeholder="Search teams or courts..."
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
            bg={inputBg}
            borderRadius="md"
          />
        </InputGroup>
        
        <Flex alignItems="center" gap={2}>
          <Icon as={MdFilterList} color="gray.500" />
          <Select 
            value={selectedCourt} 
            onChange={(e) => setSelectedCourt(e.target.value)}
            maxW={isMobile ? "100%" : "200px"}
            bg={inputBg}
          >
            <option value="all">All Courts</option>
            {courts.map((court: CourtOption) => (
              <option key={court.id} value={court.id.toString()}>
                {court.name}
              </option>
            ))}
          </Select>
        </Flex>
      </Flex>

      {/* No results message */}
      {dateLabels.length === 0 && (
        <Box textAlign="center" py={10}>
          <Text fontSize="lg">No games match your search criteria.</Text>
          <Text color="gray.500" mt={2}>Try adjusting your filters or search terms.</Text>
        </Box>
      )}

      {/* Tabs with schedule */}
      {dateLabels.length > 0 && (
        <Tabs variant="soft-rounded" colorScheme="blue" isLazy>
          <TabList 
            overflowX="auto" 
            overflowY="hidden" 
            py={2} 
            css={{
              scrollbarWidth: "none",
              "&::-webkit-scrollbar": { display: "none" },
              "-webkit-overflow-scrolling": "touch"
            }}
          >
            {dateLabels.map((date) => (
              <Tab key={date} minW="120px" fontSize={isMobile ? "sm" : "md"}>
                {format(new Date(date), "MMM d (EEE)")}
              </Tab>
            ))}
          </TabList>

          <TabPanels>
            {dateLabels.map((date) => (
              <TabPanel key={date} px={0}>
                <VStack spacing={4} align="stretch" w="100%">
                  {gamesByDate[date]?.map((game: GameView) => (
                    <Box 
                      key={game.id} 
                      borderWidth="1px" 
                      borderRadius="lg" 
                      overflow="hidden"
                      bg={cardBg}
                      borderColor={borderColor}
                      p={4}
                      shadow="sm"
                    >
                      <Flex direction={isMobile ? "column" : "row"} justify="space-between" mb={2}>
                        <HStack spacing={2} mb={isMobile ? 2 : 0}>
                          <Icon as={MdAccessTime} color="gray.500" />
                          <Text fontWeight="medium">
                            {format(new Date(game.startTime), "h:mm a")} - {format(new Date(game.endTime), "h:mm a")}
                          </Text>
                        </HStack>
                        
                        <HStack spacing={2}>
                          <Icon as={MdLocationOn} color="gray.500" />
                          <Text fontWeight="medium" color="gray.600">{game.courtName}</Text>
                          {game.playoff && <Badge colorScheme="red">Playoff</Badge>}
                        </HStack>
                      </Flex>
                      
                      <Divider my={2} />
                      
                      <Flex 
                        direction="row" 
                        justify="space-between" 
                        align="center"
                        px={2}
                        py={3}
                      >
                        <VStack align="flex-start" flex={1}>
                          <Flex align="center">
                            <Icon as={MdGames} mr={2} color={accentColor} />
                            <Text fontWeight="bold" fontSize={isMobile ? "md" : "lg"}>
                              {game.homeTeamName}
                            </Text>
                          </Flex>
                          {game.played && (
                            <Text fontWeight="bold" fontSize="xl" color={accentColor}>
                              {game.homeTeamScore}
                            </Text>
                          )}
                        </VStack>

                        <Text fontWeight="bold" fontSize="lg" mx={4}>vs</Text>
                        
                        <VStack align="flex-end" flex={1}>
                          <Flex align="center">
                            <Text fontWeight="bold" fontSize={isMobile ? "md" : "lg"}>
                              {game.awayTeamName}
                            </Text>
                            <Icon as={MdGames} ml={2} color={accentColor} />
                          </Flex>
                          {game.played && (
                            <Text fontWeight="bold" fontSize="xl" color={accentColor}>
                              {game.awayTeamScore}
                            </Text>
                          )}
                        </VStack>
                      </Flex>
                      
                      {game.played && (
                        <Box mt={2} bg="gray.50" borderRadius="md" p={2} textAlign="center">
                          <Text color="gray.600">
                            {game.homeTeamScore > game.awayTeamScore 
                              ? `${game.homeTeamName} won by ${game.homeTeamScore - game.awayTeamScore}` 
                              : game.homeTeamScore < game.awayTeamScore 
                              ? `${game.awayTeamName} won by ${game.awayTeamScore - game.homeTeamScore}`
                              : "Game ended in a tie"}
                          </Text>
                        </Box>
                      )}
                    </Box>
                  ))}
                </VStack>
              </TabPanel>
            ))}
          </TabPanels>
        </Tabs>
      )}
    </Box>
  );
};

// Container component that handles loading states
const PublicScheduleView = (): ReactElement => {
  const { eventId } = useParams<{ eventId: string }>();
  const { data: event, isLoading: eventLoading } = useGetEventByIdQuery(
    Number(eventId)
  );
  const { data: schedule, isLoading } = useGetScheduleForEventQuery(
    Number(eventId)
  );
  const [isMobile] = useMediaQuery("(max-width: 768px)");

  if (isLoading || eventLoading) {
    return <LoadingView />;
  }

  if (!schedule || !event) {
    return <ErrorView />;
  }

  return <ScheduleDisplay event={event} schedule={schedule} isMobile={isMobile} />;
};

export default PublicScheduleView; 