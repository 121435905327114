import { Tab, TabList, TabPanel, TabPanels, Tabs } from '@chakra-ui/react';
import { GameStats, PlayerStats } from 'types';
import { TeamScoringPanel } from './TeamScoringPanel';

interface SingleTeamScoringProps {
  editable?: boolean;
  overriding?: boolean;
  isAdmin?: boolean;
  gameStatsData: GameStats;
  setSelectedPlayer: (player: PlayerStats) => void;
  refetchGameStats?: () => void;
}

export const SingleTeamScoring: React.FC<SingleTeamScoringProps> = ({
  editable,
  overriding,
  isAdmin,
  gameStatsData,
  setSelectedPlayer,
  refetchGameStats,
}) => {
  return (
    <Tabs isFitted variant="enclosed">
      <TabList>
        <Tab>
          Home: #{gameStatsData.game.homeTeamDrawId || gameStatsData.game.homeTeamId}{' '}
          {gameStatsData.game.homeTeamName}
        </Tab>
        <Tab>
          Away: #{gameStatsData.game.awayTeamDrawId ||gameStatsData.game.awayTeamId}{' '}
          {gameStatsData.game.awayTeamName}
        </Tab>
      </TabList>
      <TabPanels>
        <TabPanel>
          <TeamScoringPanel
            gameId={gameStatsData.game.id}
            mvpId={gameStatsData.game.mvpId}
            overriding={isAdmin && overriding}
            editable={editable}
            teamName={gameStatsData.game.homeTeamName}
            players={gameStatsData.homeTeam}
            setSelectedPlayer={setSelectedPlayer}
            triggerRefetch={refetchGameStats}
          />
        </TabPanel>
        <TabPanel>
          <TeamScoringPanel
            gameId={gameStatsData.game.id}
            mvpId={gameStatsData.game.mvpId}
            overriding={isAdmin && overriding}
            editable={editable}
            teamName={gameStatsData.game.awayTeamName}
            players={gameStatsData.awayTeam}
            setSelectedPlayer={setSelectedPlayer}
            triggerRefetch={refetchGameStats}
          />
        </TabPanel>
      </TabPanels>
    </Tabs>
  );
};
