import {
  configureStore,
  createSlice,
  isRejectedWithValue,
  Middleware,
  MiddlewareAPI,
} from '@reduxjs/toolkit';
import { setupListeners } from '@reduxjs/toolkit/query/react';
import { stat } from 'fs';
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';
import { OrganizationUser } from 'types/dto';
import { User } from '../types';
import { login, refresh, yogiCupApi, assumeOrgUser } from './api';
import { addNotification, notificationsSlice } from './slices/notification';

export interface UserState {
  token?: string;
  user?: User;
  isAuthenticated: boolean;
  organizationUser?: OrganizationUser;
}

const userSlice = createSlice({
  name: 'user',
  initialState: {
    token: undefined,
    user: undefined,
    isAuthenticated: false,
  } as UserState,
  reducers: {
    logout: (state, action) => {
      state.token = undefined;
      state.user = undefined;
      state.isAuthenticated = false;
      window.location.reload();
    },
  },
  extraReducers: (builder) => {
    builder.addMatcher(login.matchFulfilled, (state, action) => {
      state.token = action.payload.accessToken;
      state.user = action.payload.user;
      state.isAuthenticated = true;
    });
    builder.addMatcher(refresh.matchFulfilled, (state, action) => {
      state.token = action.payload.accessToken;
      state.user = action.payload.user;
      state.isAuthenticated = true;
      if (action.payload.user.organizationUser) {
        state.organizationUser = action.payload.user.organizationUser;
      }
    });
    builder.addMatcher(assumeOrgUser.matchFulfilled, (state, action) => {
      state.token = action.payload.accessToken;
      state.user = action.payload.user;
      state.isAuthenticated = true;
      if (action.payload.user.organizationUser) {
        state.organizationUser = action.payload.user.organizationUser;
      }
    });
  },
});

const rtkQueryErrorToast: Middleware =
  (api: MiddlewareAPI) => (next) => (action) => {
    if (isRejectedWithValue(action)) {
      if (
        action.payload.status === 401 &&
        action.meta.arg.endpointName !== 'refresh'
      ) {
        // window.location.reload();
      } else {
        if (action.meta.arg.endpointName !== 'refresh') {
          api.dispatch(
            addNotification({
              message: action.payload.message,
              type: 'error',
            })
          );
        }
      }
    }
    return next(action);
  };

export const store = configureStore({
  reducer: {
    // Add the generated reducer as a specific top-level slice
    [yogiCupApi.reducerPath]: yogiCupApi.reducer,
    [userSlice.name]: userSlice.reducer,
    [notificationsSlice.name]: notificationsSlice.reducer,
  },
  // Adding the api middleware enables caching, invalidation, polling,
  // and other useful features of `rtk-query`.
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware()
      .concat(yogiCupApi.middleware)
      .concat(rtkQueryErrorToast),
});

// optional, but required for refetchOnFocus/refetchOnReconnect behaviors
// see `setupListeners` docs - takes an optional callback as the 2nd arg for customization
setupListeners(store.dispatch);

export const { logout } = userSlice.actions;
export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
export const useAppDispatch = () => useDispatch<AppDispatch>();
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;
